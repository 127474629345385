import { Suspense, lazy } from "react";
import { HashRouter as Router, Switch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { LoadingPage } from "./components";
import PrivateRoute from "./helpers/PrivateRoute";
import Medicamentos from "./pages/medicamentos";

// const currentKey = location.pathname.split('/')[1] || '/';

const currentKey = "";
const animationName = "rag-fadeIn";
const timeout = { enter: 500, exit: 500 };

const Login = lazy(() => import("./pages/auth/login"));
const Register = lazy(() => import("./pages/auth/register"));
const PhoneNumber = lazy(() => import("./pages/auth/phoneNumber"));
const Authentication = lazy(() => import("./pages/auth/authentication"));
const Whatsapp = lazy(() => import("./pages/auth/whatsapp"));
const Home = lazy(() => import("./pages/home"));

const Laboratorios = lazy(() => import("./pages/laboratorios/index"));
const LaboratoriosDoctors = lazy(
  () => import("./pages/laboratorios/labsDoctors")
);
const LaboratorioDetails = lazy(
  () => import("./pages/laboratorios/details/index")
);
const LaboratoriosOutros = lazy(() => import("./pages/laboratorios/outros"));
const LaboratoriosAssociados = lazy(
  () => import("./pages/laboratorios/associados")
);

const LaboratoriosCadastro = lazy(() => import("./pages/laboratorios/create"));
const LaboratoriosEditar = lazy(() => import("./pages/laboratorios/update"));
const LaboratoriosConteudo = lazy(
  () => import("./pages/laboratorios/conteudo")
);
const LaboratoriosShowConteudo = lazy(
  () => import("./pages/laboratorios/showConteudo")
);

const InteracoesMedicamentosas = lazy(
  () => import("./pages/interacoesMedicamentosas/index")
);
const Interacoes = lazy(
  () => import("./pages/interacoesMedicamentosas/interacoes")
);

const ConteudoTipos = lazy(() => import("./pages/conteudoTipos/index"));
const ConteudoTiposCadastro = lazy(() => import("./pages/conteudoTipos/create"));
const ConteudoTiposEditar = lazy(() => import("./pages/conteudoTipos/update"));

const Categorias = lazy(() => import("./pages/categorias/index"));
const CategoriasCadastro = lazy(() => import("./pages/categorias/create"));
const CategoriasEditar = lazy(() => import("./pages/categorias/update"));

const Subcategorias = lazy(() => import("./pages/subcategorias/index"));
const SubcategoriasCadastro = lazy(
  () => import("./pages/subcategorias/create")
);
const SubcategoriasEditar = lazy(() => import("./pages/subcategorias/update"));

const Representantes = lazy(() => import("./pages/representantes/index"));
const GerenciarRepresentantes = lazy(
  () => import("./pages/representantes/gerenciar")
);
const RepresentantesCadastro = lazy(
  () => import("./pages/representantes/create")
);
const RepresentantesEditar = lazy(
  () => import("./pages/representantes/update")
);

const Links = lazy(() => import("./pages/links/index"));
const LinkCadastro = lazy(() => import("./pages/links/create"));

const Usuarios = lazy(() => import("./pages/usuarios/index"));
const UsuariosCadastro = lazy(() => import("./pages/usuarios/create"));
const UsuariosEditar = lazy(() => import("./pages/usuarios/update"));

const Medicos = lazy(() => import("./pages/medicos/index"));
const ShowDoctor = lazy(() => import("./pages/medicos/show"));

const Conteudo = lazy(() => import("./pages/conteudos/index"));
const ConteudoCadastro = lazy(() => import("./pages/conteudos/create"));
const ConteudoEditar = lazy(() => import("./pages/conteudos/update"));

const Produtos = lazy(() => import("./pages/produtos/index"));
const ProdutosCadastro = lazy(() => import("./pages/produtos/create"));
const ProdutosEditar = lazy(() => import("./pages/produtos/update"));

const Apresentacoes = lazy(() => import("./pages/apresentacoes/index"));
const ApresentacoesCadastro = lazy(
  () => import("./pages/apresentacoes/create")
);
const ApresentacoesEditar = lazy(() => import("./pages/apresentacoes/update"));

const Samples = lazy(() => import("./pages/samples/index"));
const Chat = lazy(() => import("./pages/chat/index"));
const Profile = lazy(() => import("./pages/perfil/index"));

const Banners = lazy(() => import("./pages/banners/index"));
const BannersCadastro = lazy(() => import("./pages/banners/create"));
const BannersEditar = lazy(() => import("./pages/banners/update"));

const Discounts = lazy(() => import("./pages/discounts/index"));
const DiscountsList = lazy(() => import("./pages/discounts/discountsList"));
const CreateDiscounts = lazy(() => import("./pages/discounts/create"));
const EditDiscounts = lazy(() => import("./pages/discounts/edit"));

const Template = lazy(() => import("./pages/templates/index"));
const CreateTemplate = lazy(() => import("./pages/templates/create"));
const EditTemplate = lazy(() => import("./pages/templates/update"));

const Monitoramento = lazy(() => import("./pages/monitoring/index"));

const Embed = lazy(() => import("./pages/embed/"));

const Laudo = lazy(() => import("./pages/laudo/index"));
const LaudoPDF = lazy(() => import("./pages/laudoPDF/index"));

const Routes = () => (
  <Router>
    <CSSTransition
      key={currentKey}
      timeout={timeout}
      classNames={animationName}
      exit={false}
    >
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <PrivateRoute exact path="/login" component={Login} />
          <PrivateRoute exact path="/auth/login" component={Login} />
          <PrivateRoute
            exact
            path="/auth/authentication"
            component={Authentication}
          />
          <PrivateRoute exact path="/auth/criar-conta" component={Register} />
          <PrivateRoute
            exact
            path="/auth/phone-number"
            component={PhoneNumber}
          />
          <PrivateRoute exact path="/whatsapp/:id" component={Whatsapp} />

          <PrivateRoute exact path="/">
            <Home />
          </PrivateRoute>
          <PrivateRoute exact path="/home">
            <Home />
          </PrivateRoute>

          <PrivateRoute exact path="/laboratorios/">
            <Laboratorios />
          </PrivateRoute>
          <PrivateRoute exact path="/laboratoriosMedicos/">
            <LaboratoriosDoctors />
          </PrivateRoute>
          <PrivateRoute exact path="/laboratorio/:id">
            <LaboratorioDetails />
          </PrivateRoute>
          <PrivateRoute exact path="/laboratorios/outros">
            <LaboratoriosOutros />
          </PrivateRoute>
          <PrivateRoute exact path="/laboratorios/associados">
            <LaboratoriosAssociados />
          </PrivateRoute>
          <PrivateRoute exact path="/laboratorios/cadastro">
            <LaboratoriosCadastro />
          </PrivateRoute>
          <PrivateRoute exact path="/laboratorios/:id/editar">
            <LaboratoriosEditar />
          </PrivateRoute>
          <PrivateRoute exact path="/laboratorios/:id/conteudos">
            <LaboratoriosConteudo />
          </PrivateRoute>
          <PrivateRoute exact path="/laboratorios/:id/conteudos/:content_id">
            <LaboratoriosShowConteudo />
          </PrivateRoute>
          <PrivateRoute exact path="/laboratorios/discounts">
            <DiscountsList />
          </PrivateRoute>

          <PrivateRoute exact path="/interacoesMedicamentosas">
            <InteracoesMedicamentosas />
          </PrivateRoute>
          <PrivateRoute exact path="/interacoes/:ids">
            <Interacoes />
          </PrivateRoute>

          <PrivateRoute exact path="/conteudo-tipos">
            <ConteudoTipos />
          </PrivateRoute>
          <PrivateRoute exact path="/conteudo-tipos/cadastro">
            <ConteudoTiposCadastro />
          </PrivateRoute>
          <PrivateRoute exact path="/conteudo-tipos/:id/editar">
            <ConteudoTiposEditar />
          </PrivateRoute>

          <PrivateRoute exact path="/categorias">
            <Categorias />
          </PrivateRoute>
          <PrivateRoute exact path="/categorias/cadastro">
            <CategoriasCadastro />
          </PrivateRoute>
          <PrivateRoute exact path="/categorias/:id/editar">
            <CategoriasEditar />
          </PrivateRoute>

          <PrivateRoute exact path="/subcategorias">
            <Subcategorias />
          </PrivateRoute>
          <PrivateRoute exact path="/subcategorias/cadastro">
            <SubcategoriasCadastro />
          </PrivateRoute>
          <PrivateRoute exact path="/subcategorias/:id/editar">
            <SubcategoriasEditar />
          </PrivateRoute>

          <PrivateRoute exact path="/medicamentos">
            <Medicamentos />
          </PrivateRoute>
          {/* <PrivateRoute exact path="/medicamentos/selecionados"><MedicamentosSelecionados /></PrivateRoute> */}

          <PrivateRoute exact path="/representantes">
            <Representantes />
          </PrivateRoute>
          <PrivateRoute exact path="/representantes/gerenciar">
            <GerenciarRepresentantes />
          </PrivateRoute>
          <PrivateRoute exact path="/representantes/cadastro">
            <RepresentantesCadastro />
          </PrivateRoute>
          <PrivateRoute exact path="/representantes/:id/editar">
            <RepresentantesEditar />
          </PrivateRoute>

          <PrivateRoute exact path="/links">
            <Links />
          </PrivateRoute>
          <PrivateRoute exact path="/links/cadastro">
            <LinkCadastro />
          </PrivateRoute>

          <PrivateRoute exact path="/usuarios">
            <Usuarios />
          </PrivateRoute>
          <PrivateRoute exact path="/usuarios/cadastro">
            <UsuariosCadastro />
          </PrivateRoute>
          <PrivateRoute exact path="/usuarios/:id/editar">
            <UsuariosEditar />
          </PrivateRoute>

          <PrivateRoute exact path="/medicos">
            <Medicos />
          </PrivateRoute>
          <PrivateRoute exact path="/medicos/:id/show">
            <ShowDoctor />
          </PrivateRoute>

          <PrivateRoute exact path="/conteudos">
            <Conteudo />
          </PrivateRoute>
          <PrivateRoute exact path="/conteudos/cadastro">
            <ConteudoCadastro />
          </PrivateRoute>
          <PrivateRoute exact path="/conteudos/:id/editar">
            <ConteudoEditar />
          </PrivateRoute>

          <PrivateRoute exact path="/produtos">
            <Produtos />
          </PrivateRoute>
          <PrivateRoute exact path="/produtos/cadastro">
            <ProdutosCadastro />
          </PrivateRoute>
          <PrivateRoute exact path="/produtos/:id/editar">
            <ProdutosEditar />
          </PrivateRoute>

          <PrivateRoute exact path="/apresentacoes">
            <Apresentacoes />
          </PrivateRoute>
          <PrivateRoute exact path="/apresentacoes/cadastro">
            <ApresentacoesCadastro />
          </PrivateRoute>
          <PrivateRoute exact path="/apresentacoes/:id/editar">
            <ApresentacoesEditar />
          </PrivateRoute>

          <PrivateRoute exact path="/amostras/:laboratories_id/laboratorio">
            <Samples />
          </PrivateRoute>
          <PrivateRoute exact path="/chat">
            <Chat />
          </PrivateRoute>
          <PrivateRoute exact path="/perfil">
            <Profile />
          </PrivateRoute>

          <PrivateRoute exact path="/banners">
            <Banners />
          </PrivateRoute>
          <PrivateRoute exact path="/banners/cadastro">
            <BannersCadastro />
          </PrivateRoute>
          <PrivateRoute exact path="/banners/:id/editar">
            <BannersEditar />
          </PrivateRoute>

          <PrivateRoute exact path="/discounts">
            <Discounts />
          </PrivateRoute>
          <PrivateRoute exact path="/discounts/cadastro">
            <CreateDiscounts />
          </PrivateRoute>
          <PrivateRoute exact path="/discounts/:id/editar">
            <EditDiscounts />
          </PrivateRoute>

          <PrivateRoute exact path="/templates">
            <Template />
          </PrivateRoute>
          <PrivateRoute exact path="/templates/:id/editar">
            <EditTemplate />
          </PrivateRoute>
          <PrivateRoute exact path="/templates/cadastro">
            <CreateTemplate />
          </PrivateRoute>

          <PrivateRoute exact path="/monitoramento">
            <Monitoramento />
          </PrivateRoute>

          <PrivateRoute exact path="/busca-de-medicamentos">
            <Embed />
          </PrivateRoute>

          <PrivateRoute exact path="/apaconline">
            <Laudo />
          </PrivateRoute>
          <PrivateRoute exact path="/pdf-laudo-doctorhub">
            <LaudoPDF />
          </PrivateRoute>
        </Switch>
      </Suspense>
    </CSSTransition>
  </Router>
);

export default Routes;
